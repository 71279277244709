import { Checkbox, Form } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { basePath } from '../constant';

type AgreementProps = {
  slug?: string;
  fontColor?: string;
  customTc?: string;
  required?: boolean;
  brandName?: string;
  optin?: boolean;
};

const Agreement = ({ slug, fontColor, customTc, required = true, brandName, optin }: AgreementProps) => {
  const [url, setUrl] = useState(`${basePath}/${slug}/tnc`);

  useEffect(() => {
    setUrl(`${basePath}/${slug}/tnc?referrer=${window?.location?.href}`);
  }, [slug]);

  return (
    <AgreementWrapper $fontColor={fontColor}>
      <div className="each-agreement-box">
        <Form.Item
          valuePropName="checked"
          name="terms"
          className="mb-0"
          initialValue={true}
          rules={[
            () => ({
              validator(_, value) {
                if (!value && required) return Promise.reject(new Error('Please accept the terms & conditions'));
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Checkbox>
            {customTc ? (
              <div className="forms-tc-html" dangerouslySetInnerHTML={{ __html: customTc }} />
            ) : (
              <p className="agreement-text mb-0">
                By checking this box, you agree to the following{' '}
                <a href={url ?? `${basePath}/${slug}/tnc`} target="_blank" rel="noopener noreferrer">
                  Terms and Conditions
                </a>
                {!optin ? ' and agree to receive marketing communications from the merchant.' : ''}
              </p>
            )}
          </Checkbox>
        </Form.Item>
      </div>

      {optin && (
        <div className="each-agreement-box">
          <Form.Item valuePropName="checked" name="optin" className="mb-0">
            <Checkbox>
              <p className="agreement-text mb-0">
                I&apos;d like to receive information about offers, discounts, and other marketing communications from{' '}
                {brandName}
              </p>
            </Checkbox>
          </Form.Item>
        </div>
      )}
    </AgreementWrapper>
  );
};

const AgreementWrapper = styled.div<{ $fontColor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  margin-top: 10px;

  .each-agreement-box,
  .ant-checkbox-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;

    .agreement-text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      color: ${(props) => props.$fontColor || '#30384c'};
      margin-bottom: 0px;
      text-align: left;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #30384c;
    border-color: #30384c;
  }
  .ant-checkbox-checked::after {
    border: 1px solid #30384c;
  }
`;

export default Agreement;
