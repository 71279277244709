import { OfferCard } from '@momos/react-elements';
import { Form } from 'antd';
import Agreement from 'components/Agreement';
import BottomNavigation from 'components/BottomNavigation';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import VoucherHeader from 'components/VoucherHeader';
import { REGION, REGIONS } from 'constant';
import { TRACKING } from 'constant/analytics';
import { breakpoints } from 'constant/style';
import * as ga from 'lib/ga';
import { isEqual, lowerCase } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';
import { IssuanceTypeEnum, Voucher, VoucherV2 } from 'utils/types';

interface ContactDetailsProps {
  voucher?: VoucherV2 | Voucher;
  onSubmit: (values: any) => Promise<void>;
  onBack?: () => void;
  brandName: string;
  brandLogo?: string | null;
  voucherDescriptionBackgroundColor?: string;
  name?: string;
  email?: string;
  slug?: string;
  surveyId?: string;
  region?: string;
  optin?: boolean;
}

const Footnote = styled.div`
  font-size: 12px;
  color: var(--grey);
  margin-top: 10px;
`;

const ContactDetails = ({
  voucher,
  onSubmit,
  onBack,
  brandName,
  brandLogo,
  name,
  email,
  slug,
  region,
  optin,
}: ContactDetailsProps) => {
  const [form] = Form.useForm();
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  useEffect(() => {
    form.setFieldsValue({
      agreeTermsAndCondition: false,
      agreeToContractedMerchant: true,
      name,
      email,
    });
  }, [form]);

  const handleSubmit = async (values: any) => {
    ga.event(TRACKING.SURVEY.BUTTON.SUBMIT);
    setDisabledSubmit(true);
    await onSubmit(values);
    setDisabledSubmit(false);
  };

  const buttonText = voucher ? 'Claim My Voucher' : 'Submit Survey';

  const issuanceMetaInfoMarkup = !voucher?.issuanceMetaData?.isCompleted ? (
    <div className="contact-meta-desc">
      Enter your information below for a chance to win! Winners will be notified on{' '}
      {moment(voucher?.issuanceMetaData?.drawDate).format('YYYY-MM-DD')}
    </div>
  ) : (
    <div className="contact-meta-desc">The Draw is already concluded</div>
  );

  return (
    <ContactDetailsWrap>
      <VoucherHeader logo={brandLogo} title={brandName} size="large" />

      {voucher && (
        <div className="mb-20 mt-20">
          <OfferCard voucher={voucher as VoucherV2} />
        </div>
      )}

      {voucher?.issuanceType === IssuanceTypeEnum.LUCKY_DRAW ? (
        issuanceMetaInfoMarkup
      ) : (
        <div className="contact-meta-desc">
          {voucher
            ? 'In order to send you your voucher please provide us your contact information'
            : 'Please give us your contact information so that we can reach out to you.'}
        </div>
      )}

      {!voucher?.issuanceMetaData?.isCompleted && (
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <div className="user-info">
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input your name' }]}>
              <TextInput placeholder="Enter Name" onClick={() => ga.event(TRACKING.SURVEY.BUTTON.ENTER_NAME)} />
            </Form.Item>

            <Form.Item
              label="Email Address"
              name="email"
              rules={[{ required: true, type: 'email', message: 'Please input your email' }]}
            >
              <TextInput
                placeholder="Enter Email Address"
                onClick={() => ga.event(TRACKING.SURVEY.BUTTON.ENTER_EMAIL)}
              />
            </Form.Item>

            {voucher?.shouldCollectPhoneNumber && (
              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={
                  voucher?.shouldCollectPhoneNumber === 'MANDATORY'
                    ? [{ required: true, message: 'Please input your phone number' }]
                    : []
                }
              >
                <PhoneInput
                  placeholder="Enter Phone Number"
                  country={lowerCase(region || (isEqual(REGION, REGIONS.US) ? 'us' : 'sg'))}
                  inputClass={'momos-input'}
                  onClick={() => ga.event(TRACKING.SURVEY.BUTTON.ENTER_PHONE)}
                  countryCodeEditable={false}
                />
              </Form.Item>
            )}
          </div>

          <Footnote>
            <Agreement slug={slug} brandName={brandName} optin={optin} />
          </Footnote>

          <ButtonWraper>
            <Button shape="round" htmlType="submit" className="submit-button mt-4" disabled={disabledSubmit}>
              {buttonText}
            </Button>
          </ButtonWraper>
        </Form>
      )}

      {onBack && <BottomNavigation onBack={onBack} />}
    </ContactDetailsWrap>
  );
};

const ButtonWraper = styled.div`
  display: flex;
  justify-content: center;
`;

const ContactDetailsWrap = styled.div`
  width: 40%;
  margin-bottom: 80px;

  .contact-meta-desc {
    font-family: var(--font-family-medium);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: var(--slate-gray-dark);
    margin-bottom: 15px;
  }

  .user-info {
    margin-bottom: 20px;

    > div:first-child {
      margin-bottom: 10px !important;
    }

    .ant-form-item {
      margin-bottom: 10px;
    }

    .momos-input {
      font-family: var(--font-family-medium);
      color: var(--slate-gray-dark);
    }

    .ant-form-item-has-error {
      .react-tel-input {
        .form-control {
          border-color: #ff4d4f;
        }
        .flag-dropdown {
          border-color: #ff4d4f;
        }
      }
    }

    .react-tel-input {
      .flag-dropdown {
        border-radius: 550px 0 0 550px;
      }
      .form-control {
        width: 100%;
        height: 50px;
      }
    }

    .ant-form-item-label {
      label {
        font-size: 16px;
        line-height: 16px;
        font-family: var(--font-family-medium);
        color: var(--slate-gray-dark);
      }
    }
  }

  .order-info-field {
    width: 375px;
  }

  .terms-condition-wrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    label {
      margin-left: 0px;
      margin-bottom: 5px;

      span {
        font-family: var(--font-family-medium);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .submit-button {
    width: 40%;
    margin-bottom: 10px;
  }

  @media (max-width: ${breakpoints.laptop}) {
    width: 100%;
    margin-bottom: 0px;

    .user-info {
      flex-direction: column;

      > div:first-child {
        margin-right: 0px;
        margin-bottom: 20px;
      }

      .momos-input {
        width: 100%;
      }
    }

    .order-info-field {
      width: 100%;
    }

    .submit-button {
      width: 100%;
      margin-bottom: 0px;
    }
  }
`;

export default ContactDetails;
